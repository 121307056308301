import React, { useState, useEffect } from "react";
import "../style/StatusStyles.css"; // External CSS file for styles

export default function CheckBotStatus() {
  const [botOnline, setBotOnline] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredDay, setHoveredDay] = useState(null);
  const [hoveredService, setHoveredService] = useState(null);
  const [startDate, setStartDate] = useState(new Date()); // To track the start date for uptime

  // Initial downtime data with specific dates
  const initialDowntimeData = {
    "app.byxbot.com": [
      { date: new Date(2024, 8, 6), type: "partial" },
      { date: new Date(2024, 8, 5), type: "partial" },
      { date: new Date(2024, 8, 8), type: "full" },
      { date: new Date(2024, 8, 16), type: "full" },
      { date: new Date(2024, 9, 1), type: "partial" },
      { date: new Date(2024, 9, 2), type: "full" },
    ],
    "ByX Trading Bot": [
      { date: new Date(2024, 8, 10), type: "partial" },
      { date: new Date(2024, 9, 1), type: "partial" },
      { date: new Date(2024, 9, 2), type: "partial" },
    ],
    "ByX Dashboard": [
      { date: new Date(2024, 8, 16), type: "full" },
      { date: new Date(2024, 8, 15), type: "full" },
      { date: new Date(2024, 9, 1), type: "partial" },
      { date: new Date(2024, 9, 2), type: "partial" },
    ],
  };
  

  const [downtimeData, setDowntimeData] = useState(initialDowntimeData);

  useEffect(() => {
    // Simulate bot coming online after 5 seconds
    const timer = setTimeout(() => {
      if (botOnline === null) {
        setBotOnline(true);
      }
    }, 1000);

    // Set up an interval to check for new day and update data
    const dailyUpdateInterval = setInterval(() => {
      updateUptimeData();
    }, 1000 * 60 * 60 * 24); // Check every 24 hours

    return () => {
      clearTimeout(timer);
      clearInterval(dailyUpdateInterval);
    };
  }, [startDate]);

  // Function to shift the uptime data when a new day is reached
  const updateUptimeData = () => {
    const updatedDowntimeData = { ...downtimeData };

    Object.keys(updatedDowntimeData).forEach((service) => {
      updatedDowntimeData[service] = updatedDowntimeData[service].map((entry) => {
        const newDate = new Date(entry.date);
        newDate.setDate(newDate.getDate() + 1); // Shift each date by one day
        return { ...entry, date: newDate };
      });

      // Optionally add new random downtime data for the current date (for testing)
      const addRandomDowntime = Math.random() > 0.8; // 20% chance of downtime
      if (addRandomDowntime) {
        const newDowntime = {
          date: new Date(), // Add new downtime for the current date
          type: Math.random() > 0.5 ? "partial" : "full", // Randomly decide type of downtime
        };
        updatedDowntimeData[service].push(newDowntime);
      }
    });

    setDowntimeData(updatedDowntimeData);
  };

  const toggleStatus = () => setIsOpen(!isOpen);

  // Function to get uptime for each date
  const getDateUptime = (service, date) => {
    const downtime = downtimeData[service]?.find(
      (entry) => entry.date.toDateString() === date.toDateString()
    );
    if (!downtime) return 100; // 100% uptime if no downtime for the day
    return downtime.type === "full" ? 0 : 50; // 0 for full downtime, 50 for partial downtime
  };

  // Function to calculate average uptime for a service
  const calculateAverageUptime = (service) => {
    const currentDate = new Date();
    const uptimeArray = Array.from({ length: 30 }, (_, index) => {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - index); // Get the date for each of the last 30 days
      return getDateUptime(service, date);
    });
    return (uptimeArray.reduce((sum, uptime) => sum + uptime, 0) / uptimeArray.length).toFixed(2);
  };

  // Function to get label for each day
  const getDayLabel = (dayIndex) => {
    const currentDate = new Date();
    const labelDate = new Date(currentDate);
    labelDate.setDate(currentDate.getDate() - dayIndex);
    return labelDate.toLocaleDateString();
  };

  const handleMouseEnter = (dayIndex, service) => {
    setHoveredDay(dayIndex);
    setHoveredService(service);
  };

  const handleMouseLeave = () => {
    setHoveredDay(null);
    setHoveredService(null);
  };

  return (
    <div>
      <div className={`statusContainer ${isOpen ? "open" : ""}`}>
        <div className="statusHeader" onClick={toggleStatus}>
          <div className={`dot ${botOnline ? "onlineDot" : "offlineDot"}`}></div>
          <div className="statusTitle">
            Status: {botOnline ? "All systems operational" : "Loading data"}
          </div>
        </div>

        {/* Sliding content for uptime details */}
        {isOpen && (
          <div className="statusDetails">
            {Object.keys(downtimeData).map((service) => (
              <div className="statusRow" key={service}>
                <div className="statusService">{service}</div>
                <div className="averageUptime">
                  Average Uptime: {calculateAverageUptime(service)}%
                </div>
                <div className="uptimeGraph">
                  {Array.from({ length: 30 }, (_, index) => {
                    const date = new Date();
                    date.setDate(date.getDate() - index); // Get the date for each of the last 30 days
                    return (
                      <div
                        key={index}
                        className={`dayBox ${
                          getDateUptime(service, date) === 100
                            ? "fullUptime"
                            : getDateUptime(service, date) === 50
                            ? "partialUptime"
                            : "fullDowntime"
                        }`}
                        onMouseEnter={() => handleMouseEnter(index, service)}
                        onMouseLeave={handleMouseLeave}
                      ></div>
                    );
                  }).reverse() /* Reverzuj pořadí dnů */}
                </div>
              </div>
            ))}

            {/* Popup for specific day details */}
            {hoveredDay !== null && hoveredService !== null && (
              <div className="popup" style={{ top: "20%", left: "50%" }}>
                <div className="popupService">{hoveredService}</div>
                <div className="popupDay">Date: {getDayLabel(hoveredDay)}</div>
                <div className="popupDetail">
                  {getDateUptime(hoveredService, new Date()) === 100
                    ? "No downtime reported"
                    : getDateUptime(hoveredService, new Date()) === 50
                    ? "Partial downtime reported on this day"
                    : "Major downtime reported on this day"}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="overlay2" onClick={toggleStatus}></div>
    </div>
  );
}
