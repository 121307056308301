import React, { useState, useEffect } from "react";
import "../status/StatusPage.css"; // Váš externí CSS soubor

export default function CheckBotStatus() {
  const [botOnline, setBotOnline] = useState(null);
  const [isOpen, setIsOpen] = useState(true); // Status je otevřen hned od začátku
  const [hoveredDay, setHoveredDay] = useState(null);
  const [hoveredService, setHoveredService] = useState(null);

  const initialDowntimeData = {
    "app.byxbot.com": [
      { date: new Date(2024, 8, 6), type: "partial" },
      { date: new Date(2024, 8, 5), type: "partial" },
      { date: new Date(2024, 8, 8), type: "full" },
      { date: new Date(2024, 8, 16), type: "full" },
      { date: new Date(2024, 9, 1), type: "partial" },
      { date: new Date(2024, 9, 2), type: "full" },
    ],
    "ByX Trading Bot": [
      { date: new Date(2024, 8, 10), type: "partial" },
      { date: new Date(2024, 9, 1), type: "partial" },
      { date: new Date(2024, 9, 2), type: "partial" },
    ],
    "ByX Dashboard": [
      { date: new Date(2024, 8, 16), type: "full" },
      { date: new Date(2024, 8, 15), type: "full" },
      { date: new Date(2024, 9, 1), type: "partial" },
      { date: new Date(2024, 9, 2), type: "partial" },
    ],
  };

  const [downtimeData, setDowntimeData] = useState(initialDowntimeData);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (botOnline === null) {
        setBotOnline(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const toggleStatus = () => setIsOpen(!isOpen);

  const getDateUptime = (service, date) => {
    const downtime = downtimeData[service]?.find(
      (entry) => entry.date.toDateString() === date.toDateString()
    );
    if (!downtime) return 100;
    return downtime.type === "full" ? 0 : 50;
  };

  const calculateAverageUptime = (service) => {
    const currentDate = new Date();
    const uptimeArray = Array.from({ length: 30 }, (_, index) => {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - index);
      return getDateUptime(service, date);
    });
    return (uptimeArray.reduce((sum, uptime) => sum + uptime, 0) / uptimeArray.length).toFixed(2);
  };

  const getDayLabel = (dayIndex) => {
    const currentDate = new Date();
    const labelDate = new Date(currentDate);
    labelDate.setDate(currentDate.getDate() - dayIndex);
    return labelDate.toLocaleDateString();
  };

  const handleMouseEnter = (dayIndex, service) => {
    setHoveredDay(dayIndex);
    setHoveredService(service);
  };

  const handleMouseLeave = () => {
    setHoveredDay(null);
    setHoveredService(null);
  };

  return (
    <>
        <div className="login-background"></div>
    <div id="fullscreen-status-container">
      <div id="fullscreen-status-header" onClick={toggleStatus}>
        <div
          id="status-dot"
          className={botOnline ? "online-dot" : "offline-dot"}
        ></div>
        <div id="fullscreen-status-title">
          Status: {botOnline ? "All systems operational" : "Loading data"}
        </div>
      </div>

      {isOpen && (
        <div id="status-details">
          {Object.keys(downtimeData).map((service) => (
            <div className="status-row" key={service}>
              <div className="status-service">{service}</div>
              <div className="status-average-uptime">
                Average Uptime: {calculateAverageUptime(service)}%
              </div>
              <div className="status-uptime-graph">
  {Array.from({ length: 30 }, (_, index) => {
    const date = new Date();
    // Uprav index tak, aby nejnovější datum bylo vpravo
    date.setDate(date.getDate() - index);
    return (
      <div
        key={index}
        className={`uptime-day-box ${
          getDateUptime(service, date) === 100
            ? "full-uptime"
            : getDateUptime(service, date) === 50
            ? "partial-uptime"
            : "full-downtime"
        }`}
        onMouseEnter={() => handleMouseEnter(index, service)}
        onMouseLeave={handleMouseLeave}
      ></div>
    );
  }).reverse() /* Reverzuj pořadí dnů */}
</div>
            </div>
          ))}

          {hoveredDay !== null && hoveredService !== null && (
            <div id="status-popup">
              <div id="popup-service">{hoveredService}</div>
              <div id="popup-day">Date: {getDayLabel(hoveredDay)}</div>
              <div id="popup-detail">
                {getDateUptime(hoveredService, new Date()) === 100
                  ? "No downtime reported"
                  : getDateUptime(hoveredService, new Date()) === 50
                  ? "Partial downtime reported on this day"
                  : "Major downtime reported on this day"}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
    </>
  );
}
